<template>
    <div class="privacy-policy-page">
      <router-link to="/">
        <button class="back-button">
          <i class="fas fa-arrow-left"></i> Zurück zur Startseite
        </button>
      </router-link>
      <h1>Datenschutzerklärung</h1>
  
      <section>
        <h2>1. Allgemeine Hinweise</h2>
        <p>
          Der Schutz deiner persönlichen Daten ist uns wichtig. In dieser Datenschutzerklärung informieren wir dich darüber,
          welche Daten wir erheben, wie wir sie verwenden und welche Rechte du im Hinblick auf deine Daten hast. Wir behandeln
          deine personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
      </section>
  
      <section>
        <h2>2. Verantwortlicher</h2>
        <p>
          Verantwortlicher für die Datenverarbeitung auf dieser Website ist:
        </p>
        <p>
          Niklas Pickhardt<br />
          E-Mail: info@spottedhsk.de
        </p>
      </section>
  
      <section>
        <h2>3. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck ihrer Verwendung</h2>
        <h3>a) Beim Besuch der Website</h3>
        <p>
          Beim Aufrufen unserer Website werden durch den auf deinem Endgerät zum Einsatz kommenden Browser automatisch Informationen
          an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sogenannten Logfile gespeichert.
        </p>
        <ul>
          <li>IP-Adresse des anfragenden Rechners</li>
          <li>Datum und Uhrzeit des Zugriffs</li>
          <li>Name und URL der abgerufenen Datei</li>
          <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
          <li>verwendeter Browser und ggf. das Betriebssystem deines Rechners sowie der Name deines Access-Providers</li>
        </ul>
  
        <h3>b) Bei Nutzung des Kontaktformulars / Hochladen von "Spots" / Sperren von Kennzeichen</h3>
        <p>
          Wenn du uns über ein Formular oder eine andere Eingabemöglichkeit auf unserer Website Daten zur Verfügung stellst (z. B. für das Hochladen von Beobachtungen oder "Spots" oder zum Sperren von Kennzeichen), speichern wir diese Informationen einschließlich deiner IP-Adresse.
        </p>
      </section>

      <section>
        <h2>4. Erhebung der IP-Adresse</h2>
        <p>
          Bei der Nutzung unseres Formulars (z. B. beim Hochladen von "Spots" oder beim Sperren eines Kennzeichens) speichern wir auch die IP-Adresse des anfragenden Geräts. Diese Speicherung erfolgt aus Sicherheitsgründen und um mögliche Missbrauchsfälle nachvollziehen zu können. Die IP-Adresse wird vertraulich behandelt und nicht an Dritte weitergegeben, außer es besteht eine gesetzliche Pflicht zur Weitergabe oder es dient der Durchsetzung unserer Rechte.
        </p>
      </section>
  
      <section>
        <h2>5. Weitergabe von Daten</h2>
        <p>
          Eine Übermittlung deiner persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.
        </p>
        <p>
          Wir geben deine persönlichen Daten nur an Dritte weiter, wenn:
        </p>
        <ul>
          <li>du deine nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt hast,</li>
          <li>die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist,</li>
          <li>für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht,</li>
          <li>dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit dir erforderlich ist.</li>
        </ul>
      </section>
  
      <section>
        <h2>6. Cookies</h2>
        <p>
          Unsere Website verwendet Cookies. Cookies sind kleine Dateien, die auf deinem Endgerät gespeichert werden und bestimmte
          Einstellungen und Daten zum Austausch mit unserem System über deinen Browser speichern.
        </p>
        <p>
          Du kannst deinen Browser so einstellen, dass du über das Setzen von Cookies informiert wirst und Cookies nur im Einzelfall
          erlaubst, die Annahme von Cookies für bestimmte Fälle oder generell ausschließt sowie das automatische Löschen der Cookies
          beim Schließen des Browsers aktivierst.
        </p>
      </section>
  
      <section>
        <h2>7. Betroffenenrechte</h2>
        <p>Du hast das Recht:</p>
        <ul>
          <li>gemäß Art. 15 DSGVO Auskunft über deine von uns verarbeiteten personenbezogenen Daten zu verlangen,</li>
          <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung deiner bei uns gespeicherten personenbezogenen Daten zu verlangen,</li>
          <li>gemäß Art. 17 DSGVO die Löschung deiner bei uns gespeicherten personenbezogenen Daten zu verlangen,</li>
          <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung deiner personenbezogenen Daten zu verlangen,</li>
          <li>gemäß Art. 20 DSGVO deine personenbezogenen Daten, die du uns bereitgestellt hast, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen,</li>
          <li>gemäß Art. 7 Abs. 3 DSGVO deine einmal erteilte Einwilligung jederzeit uns gegenüber zu widerrufen und</li>
          <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.</li>
        </ul>
      </section>
  
      <section>
        <h2>8. Datensicherheit</h2>
        <p>
          Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils
          höchsten Verschlüsselungsstufe, die von deinem Browser unterstützt wird.
        </p>
      </section>
  
      <section>
        <h2>9. Aktualität und Änderung dieser Datenschutzerklärung</h2>
        <p>
          Diese Datenschutzerklärung ist aktuell gültig und hat den Stand November 2024. Durch die Weiterentwicklung unserer Website
          kann es notwendig werden, diese Datenschutzerklärung zu ändern.
        </p>
      </section>

    </div>
  </template>
  
  <script>
  export default {
    name: "PrivacyPolicyPage",
  };
  </script>
  
  <style scoped>
  .privacy-policy-page {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    color: #d4d4d4;
    font-family: Arial, sans-serif;
    line-height: 1.8;
    border-radius: 8px;
  }
  
  .privacy-policy-page h1 {
    font-size: 2.2em;
    color: #4ade80;
    margin-bottom: 15px;
    text-align: left;
  }
  
  .privacy-policy-page h2 {
    font-size: 1.5em;
    margin-top: 25px;
    color: #4ade80;
    text-align: left;
  }
  
  .privacy-policy-page h3 {
    font-size: 1.2em;
    margin-top: 15px;
    color: #4ade80;
  }
  
  .privacy-policy-page p,
  .privacy-policy-page ul {
    margin-bottom: 15px;
    color: #d4d4d4;
    text-align: left;
  }
  
  .privacy-policy-page ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  </style>
  
<template>
    <div class="impressum-page">
      <router-link to="/">
        <button class="back-button">
          <i class="fas fa-arrow-left"></i> Zurück zur Startseite
        </button>
      </router-link>

      <h1>Impressum</h1>
      
      <p><strong>Verantwortlich für den Inhalt gemäß § 5 TMG:</strong></p>
      <p>
        Niklas Pickhardt<br />
        Lange Wende 66<br />
        59755 Arnsberg<br />
      </p>

      <p><strong>Kontakt:</strong></p>
      <p>
        E-Mail: info@spottedhsk.de
      </p>
      
      <h2>Weitere Informationen</h2>
      <p>
        Weitere Informationen und spezifische Details zum Betrieb der Website, wie Haftungsausschlüsse und Urheberrechtshinweise, stellen wir auf Anfrage zur Verfügung. <br> Bitte kontaktieren Sie uns über die oben genannte E-Mail-Adresse.
      </p>

      
    </div>

</template>

<script>
export default {
    name: "ImpressumPage",
};
</script>

<style scoped>
/* Unterstrich und Standard-Link-Styling entfernen */
.back-button {
  background-color: transparent;
  border: none;
  color: #4ade80; /* Beibehaltung der grünen Farbe */
  font-size: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  padding: 5px;
  font-weight: bold;
  text-decoration: none; /* Unterstrich entfernen */
}

/* Für den Fall, dass der Browser zusätzlich den Link stilisiert */
.back-button:link,
.back-button:visited {
  text-decoration: none; /* Unterstrich auch bei Links verhindern */
  color: inherit; /* Farbe beibehalten */
}

.back-button i {
  font-size: 1.2em;
}

.impressum-page {
    max-width: 800px;
    margin:  0px auto;
    padding: 20px;
    color: #f0f0f0;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    text-align: left; /* Links ausgerichtet */
}

.impressum-page h1 {
    font-size: 2em;
    color: #4ade80;
    margin-bottom: 20px;
}

.impressum-page h2 {
    font-size: 1.5em;
    margin-top: 20px;
}

.impressum-page p {
    color: #b0b0b0;
    margin-bottom: 15px;
}

.impressum-page strong {
    color: #4ade80;
}
</style>

<template>
  <div class="rules-page">
          <!-- Zurück Button -->
          <button @click="goBack" class="back-button">
        <i class="fas fa-arrow-left"></i> Zurück
      </button>
    <h1>Regeln zum Einreichen von Anfragen</h1>

    <section>
      <h2>1. Allgemeine Hinweise</h2>
      <p>
        Bitte beachte die folgenden Regeln, um sicherzustellen, dass deine Anfrage angenommen wird. Die Einhaltung dieser Richtlinien
        hilft dabei, eine freundliche und respektvolle Plattform zu bewahren.
      </p>
    </section>

    <section>
      <h2>2. Respekt und Höflichkeit</h2>
      <p>
        Sei stets respektvoll und höflich in deinen Anfragen. Beleidigende oder herabsetzende Sprache ist untersagt und führt zur Ablehnung
        der Anfrage.
      </p>
    </section>

    <section>
      <h2>3. Respektiere die Privatsphäre anderer</h2>
      <p>
        Veröffentliche keine Anfragen, die dazu führen könnten, dass andere Personen belästigt, verfolgt oder unerwünscht kontaktiert werden. Das Veröffentlichen privater Informationen ohne Zustimmung ist untersagt.
      </p>
    </section>

    <section>
      <h2>4. Diskriminierung oder Hassreden</h2>
      <p>
        Jede Form von Diskriminierung, Rassismus, Sexismus oder Hassrede ist strikt verboten. Wir tolerieren keine Inhalte, die Menschen
        aufgrund ihrer Herkunft, Religion, Geschlechtsidentität, sexuellen Orientierung oder anderen persönlichen Merkmalen herabwürdigen.
      </p>
    </section>

    <section>
      <h2>5. Aufruf zur Gewalt</h2>
      <p>
        Aufrufe zur Gewalt, Bedrohungen oder Beleidigungen gegen andere Personen sind streng untersagt. Wir möchten ein friedliches und
        sicheres Umfeld fördern.
      </p>
    </section>

    <section>
      <h2>6. Sensible Inhalte</h2>
      <p>
        Anfragen, die sensible oder vertrauliche Informationen wie Bankdaten, medizinische Details oder Informationen über Minderjährige
        enthalten, sind nicht erlaubt und werden abgelehnt.
      </p>
    </section>

    <section>
      <h2>7. Werbung oder kommerzielle Inhalte</h2>
      <p>
        Diese Plattform dient nicht der Werbung für Produkte, Dienstleistungen oder andere kommerzielle Angebote. Anfragen, die
        ausschließlich der Werbung dienen, werden abgelehnt.
      </p>
    </section>

    <section>
      <h2>8. Illegale Aktivitäten</h2>
      <p>
        Anfragen, die Werbung für illegale Aktivitäten enthalten oder zur Teilnahme an diesen auffordern (z. B. Betrug, Schwarzarbeit, gefälschte Ausweise), werden nicht toleriert und können zur dauerhaften Sperrung führen.
      </p>
    </section>

    <section>
      <h2>9. Unangebrachte Inhalte</h2>
      <p>
        Inhalte, die als unangemessen gelten könnten, wie z. B. sexuelle Inhalte, sind auf unserer Plattform nicht erlaubt. Die Plattform
        soll ein sicherer Ort für alle Altersgruppen sein.
      </p>
    </section>

    <section>
      <h2>10. Diskriminierungsverbot</h2>
      <p>
        Inhalte, die sich gegen bestimmte Gruppen oder Gemeinschaften richten oder diskriminierend sind, werden nicht toleriert.
      </p>
    </section>

    <section>
      <h2>11. Wiederholte Anfragen</h2>
      <p>
        Stelle die gleiche Anfrage nicht mehrfach in kurzen Abständen. Wenn deine Anfrage nicht hochgeladen wurde, respektiere diese Entscheidung und sende sie nicht erneut ein.
      </p>
    </section>

    <section>
      <h2>12. Recht auf Ablehnung oder Löschung</h2>
      <p>
        spottedHSK behält sich das Recht vor, Anfragen ohne Angabe von Gründen abzulehnen oder zu löschen, insbesondere wenn sie gegen unsere Richtlinien oder Gesetze verstoßen.
      </p>
    </section>

    <section>
      <h2>13. Einhaltung deutscher Gesetze</h2>
      <p>
        Alle Anfragen müssen den geltenden deutschen Gesetzen entsprechen. Illegale Anfragen, einschließlich solcher, die zu strafbaren
        Handlungen aufrufen oder diese unterstützen, werden nicht toleriert. spottedHSK wird bei Bedarf mit den zuständigen Behörden
        zusammenarbeiten.
      </p>
    </section>

    <section>
      <h2>14. Melden von Verstößen</h2>
      <p>
        Wenn du einen Verstoß gegen diese Regeln oder Inhalte bemerkst, die gegen die Richtlinien verstoßen, melde dies bitte an uns, damit wir entsprechende Maßnahmen ergreifen können.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "RulesPage",
  methods:{
        // Funktion zum Zurücknavigieren
        goBack() {
      this.$router.go(-1); // Eine Seite zurück
    }
  }
};
</script>

<style scoped>
.rules-page {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  color: #d4d4d4;
  font-family: Arial, sans-serif;
  line-height: 1.8;
  border-radius: 8px;
}

.rules-page h1 {
  font-size: 2.2em;
  color: #4ade80;
  margin-bottom: 15px;
  text-align: left;
}

.rules-page h2 {
  font-size: 1.5em;
  margin-top: 25px;
  color: #4ade80;
  text-align: left;
}

.rules-page p {
  margin-bottom: 15px;
  color: #d4d4d4;
  text-align: left;
}


.back-button {
    background-color: transparent;
    border: none;
    color: #4ade80;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    padding: 5px;
    font-weight: bold;
  }
  
  .back-button i {
    font-size: 1.2em;
  }

</style>
